<template>

    <div class="h-auto w-full mb-4 rounded-xl flex flex-col overflow-hidden shadow_module">
        
        <div class="h-12 flex flex-row justify-start items-center px-4" :class="'bg-bg-'+mode" @click="goToClient(data)">
            
            <span class="text-md font-bold leading-none truncate" :class="'text-dfont-'+mode">{{ data.Name }} <br>
            <span :class="'text-2xs font-medium text-darkgreen-' + mode">{{data.ClientType}}</span>
            </span>

            <i class="mdi mdi-arrow-right text-2xl text-red ml-auto"></i>
            
        </div>

        <div class="flex flex-1 min-h-0 flex-col py-2" :class="'bg-box-'+mode">

            <div v-if="!reducetype" class="h-auto px-2"  @click="goToClient(data)">

                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}}</span>
                    <span v-if="dimensionName == 'Precio medio'" class="text-xs" :class="'text-font_gray-'+mode">{{$t('averagePrice')}}</span>
                    <span v-if="dimensionName == 'Margen'" class="text-xs" :class="'text-font_gray-'+mode">{{$t('margin')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('scope')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.MonthPreview | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">Real {{yearant}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.PastYear | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('variation')}} {{periodo}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{data.MonthVariation | reduceBigNumbers(2)}} <span v-html="unit3"></span></span>
                </div>

            </div>

            <div v-else class="h-auto px-2">

                <div class="h-auto flex flex-row justify-between items-center px-2"   @click="goToClient(data)">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('billing')}}</span>
                    <span v-if="data.Sale" class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Sale | reduceBigNumbers()}} €</span>
                    <span v-else class="text-sm font-medium" :class="'text-dfont-'+mode">-</span>
                </div>

                <div class="h-auto flex flex-row justify-between items-center px-2"  @click="goToClient(data)">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('meters')}}</span>
                    <span v-if="data.Meters" class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Meters | reduceBigNumbers()}} m<sup>2</sup></span>
                    <span v-else class="text-sm font-medium" :class="'text-dfont-'+mode">-</span>
                </div>

                <div v-if="data.Latitude" class="h-auto w-full flex flex-row justify-center items-center p-2">

                    <div class="h-8 w-40 rounded-lg flex flex-row justify-center items-center border" :class="'border-dfont-'+mode">

                        <a v-if="data.Latitude" :href="'https://www.google.com/maps/search/?api=1&query=' + data.Latitude + ',' + data.Longitude + ''">
                            <span class="font-bold text-2xs" :class="'text-darkgreen-'+mode">{{$t('howToGetThere')}}</span>
                        </a>

                    </div>

                </div>

            </div>

        </div>
        
    </div>

    <!-- <div class="w-full rounded-lg shadow_box flex flex-col p-2 mb-6 cursor-pointer" :class="'bg-module-'+mode">

        <div class="h-12 flex flex-row" @click="goToClient(data)">

            <div class="h-full w-10/12">

                <div class="h-full w-full flex flex-col justify-center items-start px-2 truncate">
                    <span class=" font-semibold truncate" :class="'text-dfont-'+mode">{{data.Name || 'No Name'}}</span>
                    <span :class="'text-xs font-semibold text-' + colortype(data.ClientType) + ''">{{data.ClientType}}</span>
                </div>

            </div>

            <div class="h-full w-2/12 flex flex-row justify-end items-center px-2">

                <i class="mdi mdi-arrow-right font-semibold" :class="'text-dfont-'+mode"></i>

            </div>

        </div>

        <div v-if="!reducetype" class="h-auto mt-2"  @click="goToClient(data)">

            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="text-sm text-darkgray">{{$t('accumulated')}}</span>
                <span v-if="dimensionName == 'Precio medio'" class="text-sm text-darkgray">{{$t('averagePrice')}}</span>
                <span v-if="dimensionName == 'Margen'" class="text-sm text-darkgray">{{$t('margin')}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{$t('deliveryNotes')}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{$t('scope')}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.MonthPreview | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">Real {{yearant}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.PastYear | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{$t('variation')}} {{periodo}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{data.MonthVariation | reduceBigNumbers(2)}} <span v-html="unit3"></span></span>
            </div>

        </div>

        <div v-else class="h-auto">

            <div class="h-auto flex flex-row justify-between items-center px-2 mt-2"   @click="goToClient(data)">
                <span class="text-sm text-gray">{{$t('billing')}}</span>
                <span v-if="data.Sale" class="text-sm font-semibold" :class="'text-dfont-'+mode">{{data.Sale | reduceBigNumbers()}} €</span>
                <span v-else class="text-sm font-semibold" :class="'text-dfont-'+mode">-</span>
            </div>

            <div class="h-auto flex flex-row justify-between items-center px-2"  @click="goToClient(data)">
                <span class="text-sm text-gray">{{$t('meters')}}</span>
                <span v-if="data.Meters" class="text-sm font-semibold" :class="'text-dfont-'+mode">{{data.Meters | reduceBigNumbers()}} m<sup>2</sup></span>
                <span v-else class="text-sm font-semibold" :class="'text-dfont-'+mode">-</span>
            </div>

            <div v-if="data.Latitude" class="h-auto w-full flex flex-row justify-center items-center p-2">

                <div class="h-full w-full bg-blue2 rounded-lg flex flex-row justify-center items-center py-1">

                    <a v-if="data.Latitude" :href="'https://www.google.com/maps/search/?api=1&query=' + data.Latitude + ',' + data.Longitude + ''">
                        <span class="font-semibold text-2xs text-white">{{$t('howToGetThere')}}</span>
                    </a>

                </div>

            </div>

        </div>

    </div> -->

</template>

<script>
import { state, actions } from '@/store';
import columnchart from './columnchart.vue';

export default {
    props:['data', 'reducetype', 'father'],
    components:{
        columnchart
    },
    data(){
        return{
            yearant: this.$moment().subtract(1, 'years').format('YYYY'),
            historical: false
        }
    },
    methods:{
        colortype(type){

            switch (type) {

                case 'ALMACENISTAS':
                    return 'blue'
                    break;
                case 'PARTICULARES':
                    return 'purple'
                    break;
                case 'CONSTRUCTORAS':
                    return 'brown'
                    break;
                case 'FABRICAS':
                    return 'orange'
                    break;
                case 'VARIOS':
                    return 'green'
                    break;
            }

        },
        goToClient(client){

            actions.setLastPage(this.$route.name)
            actions.setClientSelected(client)

            if(this.father != 'representant'){

                this.$router.push('client')

            } else {
                this.$router.push('representantclient')
            }

        }
    },
    computed:{
        dimensionName(){
            return state.dimensionName
        },
        unit(){
            let unit = '€'
            if(state.dimensionGroup == 1){
                unit = 'm<sup>2</sup>'
            }
            if(state.dimensionGroup == 3){
                unit = '%'
            }
            return unit
        },
        unit3(){
            let unit = '%'
            if(state.dimensionGroup == 2){
                unit = ' €/m<sup>2</sup>'
            }
            if(state.dimensionGroup == 3){
                unit = ''
            }
            return unit
        },
        mode(){
            return state.mode
        }
    }
}
</script>
